import React from "react";

const Footer = ({ data }) => {
  return (
    <footer
      style={{
        fontSize: "0.75rem",
        display: `flex`,
        flexWrap: `wrap`,
        justifyContent: `center`,
        padding: 0,
      }}
    >
      © {new Date().getFullYear()} jonas willett
    </footer>
  );
};

export default Footer;

import React from "react";
import { Link } from "gatsby";
// import { rhythm, scale } from "../utils/typography";
const NavBar = () => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const navItems = ["home", "services", "about"];
  return (
    <nav>
      <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `flex-end`,
          listStyle: `none`,
          padding: 0,
        }}
      >
        {navItems.map((item, index) => {
          return (
            <li
              key={index}
              style={{
                paddingLeft: 10,
              }}
            >
              <Link
                style={{
                  boxShadow: `none`,
                  color: `inherit`,
                }}
                to={item === "home" ? `/` : `/${item}`}
              >
                {item}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavBar;
